import { Component } from "react";
import { MobileView } from "react-device-detect";
import { apiV2 } from "../../services/api";

export default class FinallyAppV2 extends Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    this.setResponse(params);
  }

  setResponse(params) {
    if (params.get('token_ws') !== undefined) {
      apiV2.get("/transbank/webpay/confirmar-transaccion/" + params.get('token_ws')).then(resp => {
        console.log(resp)
      }).catch(error => {
        console.error(error)
      })
    } else {
      // respVerify = await apiV2.get("/webpay-transbank-oneclick/status/" + params.get('TBK_ORDEN_COMPRA'));
    }
  }

  render() {
    return (
      <MobileView style={{ height: '100%' }}>
        <center>
          <div style={{
            width: '60%',
            justifyContent: 'center',
            marginTop: '50%',
            fontFamily: 'Poppins',
          }}>
            <h4 style={{ color: 'black' }}>Ahora debes volver a la aplicación de Cinesol</h4>
          </div>
        </center>
      </MobileView>
    );
  }
}
