import axio from "axios";

/* export const api = axio.create(({
    baseURL: 'https://7ea1-152-204-244-1.ngrok-free.app/api',
    timeout: 5000,
})) */

/* export const api = axio.create(({
  baseURL: 'http://localhost:8000/api',
  timeout: 5000,
})) */

export const api = axio.create({
  baseURL: "https://api-cinesol.busticket.cl/api",
  timeout: 5000,
});

export const apiV2 = axio.create({
  baseURL: "https://api-cinesol-v2.busticket.cl/api",
  timeout: 5000,
});
