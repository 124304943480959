import { BrowserRouter as Router, Switch, Route, Redirect  } from "react-router-dom";

import { SignIn } from "../screens/SignIn";
import { Home } from "../screens/Home";
import { Calendary } from "../screens/Calendary";
import { Wallet } from "../screens/Wallet";
import { Mantenimiento } from "../screens/Mantenimiento";
import { Payment } from "../screens/Payment";
import { Billete } from "../screens/Billete";
import { Finally } from "../screens/Finally";
import { Cupon } from "../screens/Cupon";
import { Autenticacion } from "../screens/Autenticacion";
import { Ingresso } from "../screens/Ingresso";

import {
  Pcines,
  Listpeliculas,
  Registrar,
  Cupons,
  UsuarioPerfil,
} from "../screens/ClientesScreens/Pcines";
import FreeTicket from "../screens/Finally/FreeTicket";
import { Evento } from "../screens/Wallet/Evento";
import { EventoFinal } from "../screens/Finally/EventoFinal";
import { CuponFinal } from "../screens/Finally/CuponFinal";
import FinallyApp from "../screens/Finally/FinallyApp";
import FinallyAppV2 from "../screens/Finally/FinallyAppV2";
import FinallyInscriptionApp from "../screens/Finally/FinallyInscriptionApp";
import { RestorePass } from "../screens/RestorePassword/restorePass";
import { Butacas } from "../screens/Butacas";
import { Dashboard } from "../screens/Dashboard";

export const AppRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/finalizado-app" component={FinallyApp} />
        <Route path="/finalizado-app-v2" component={FinallyAppV2} />
        <Route path="*" component={Dashboard} />
        {/* <Route path="/login" component={SignIn} />
        <Route path="/calendario" component={Calendary} />
        <Route path="/pelicula" component={Wallet} />
        <Route path="/cupon/:cuponId" component={Cupon} />
        <Route path="/eventos/:eventoId" component={Evento} />
        <Route path="/evento-finalizado" component={EventoFinal} />
        <Route path="/cupon-finalizado" component={CuponFinal} />
        <Route path="/butacas" component={Butacas} />
        <Route path="/pago" component={Payment} />
        <Route path="/ticket" component={Billete} />
        <Route path="/finalizado" component={Finally} />
        <Route path="/finalizado-app" component={FinallyApp} />
        <Route path="/finalizado-inscription-app" component={FinallyInscriptionApp} />
        <Route path="/freeticket/:tid/:filmid" component={FreeTicket} />
        <Route path="/autenticacion" component={Autenticacion} />
        <Route path="/ingresso" component={Ingresso} />
        <Route exact path="/login" component={SignIn} />

        <Route exact path="/peliculas-en-cines" component={Pcines} />
        <Route exact path="/carteleiras-en-cines" component={Listpeliculas} />
        <Route exact path="/registrar" component={Registrar} />
        <Route exact path="/cupons-cines" component={Cupons} />
        <Route exact path="/perfil-cliente" component={UsuarioPerfil} />


        <Route path="/recoverPass" component={RestorePass} /> */}
      </Switch>
    </Router>
  );
};
